import React, { FC, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { DateTime } from 'luxon';
import { Launch } from 'superclient';
import BackgroundImageSanity from '../core/image-background-sanity';

const LaunchCard: FC<{ launch: Launch }> = ({ launch }) => {
  const [timeText, setTimeText] = useState('');
  const { slug, image } = launch;
  const { company, launchDate, launchType, overrideLaunchDate } = launch.launchInfo;

  useEffect(() => {
    const updateTimeText = () => {
      const overrideDate =
        overrideLaunchDate !== undefined && overrideLaunchDate.length > 0
          ? overrideLaunchDate
          : '';
      const timeText = overrideDate || DateTime.fromISO(launchDate.utc).toRelative();
      setTimeText(timeText);
    };

    const interval = setInterval(updateTimeText, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="launch__card x rel mb15">
      <Link to={`/launches/${slug.current}`} className="abs x y top left" />
      <div className="launch__card_image rel">
        <BackgroundImageSanity
          className="abs x y top left"
          image={image}
          alt={launchType}
          width={500}
        />
      </div>
      <div className="f jcb ais mt05">
        <div>
          <span>
            {company}
            <br />
            {launchType}
          </span>
        </div>
        <div>
          <span className="caps xsmall launch__card-timing akkura bcb cw p05">
            {timeText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LaunchCard);
