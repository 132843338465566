import React, { useState, useEffect, FC, useMemo, useRef } from 'react';
import { Link } from 'gatsby';
import { Launch, getUpcomingLaunches, getPastLaunches, Tag } from 'superclient';
import UpcomingLaunchCard from '../components/launches/launch-card-upcoming';
import LaunchCard from '../components/launches/launch-card';
import LaunchArchiveCard from '../components/launches/launch-archive-card';
import MetaModule from '../components/core/meta-module';
import { DateTime, Interval } from 'luxon';
import { useIntersect } from '../state/use-intersect';
import classNames from 'classnames';
import { Superclient } from '../clients/superclient';

const PAGE_LENGTH = 9;

const CustomStation: FC<{
  pageContext: {
    upcomingLaunches: Launch[];
    pastLaunches: Launch[];
    tags: Tag[];
  };
}> = ({
  pageContext: {
    upcomingLaunches: _upcomingLaunches,
    pastLaunches: _pastLaunches,
    tags
  }
}) => {
  const [upcomingLaunches, setUpcomingLaunches] = useState<Launch[]>(
    _upcomingLaunches
  );
  const [pastLaunches, setPastLaunches] = useState<Launch[]>(_pastLaunches);
  const content = React.createRef<HTMLDivElement>();

  const [selectedTag, setSelectedTag] = useState<Tag>();
  const [page, setPage] = useState(1);

  const loadMoreRef = useRef<any>(null);
  const [setNode, entry] = useIntersect();

  useEffect(() => {
    Superclient.getUpcomingLaunches().then((upcomingLaunches) => {
      const publishedLaunches = upcomingLaunches.filter((l) => {
        const age = Interval.fromDateTimes(
          DateTime.fromISO(l._createdAt),
          DateTime.local()
        )
          .toDuration('minutes')
          .toObject().minutes;
        return age >= 12;
      });
      setUpcomingLaunches(publishedLaunches);
      Superclient.getPastLaunches().then(setPastLaunches);
    });
  }, []);

  const nextLaunch = useMemo(
    () => (upcomingLaunches.length > 0 ? upcomingLaunches[0] : undefined),
    [upcomingLaunches]
  );

  // Past Launches

  const pastLaunchesFiltered = useMemo(() => {
    return selectedTag
      ? pastLaunches.filter((l) => {
          const tags = l.tags ? l.tags.map((t) => t._id) : [];
          return tags.includes(selectedTag._id);
        })
      : pastLaunches;
  }, [pastLaunches, selectedTag]);

  const pastLaunchResults = useMemo(() => {
    return pastLaunchesFiltered.slice(0, page * PAGE_LENGTH);
  }, [page, pastLaunchesFiltered]);

  useEffect(() => {
    setNode(loadMoreRef.current);
  }, [setNode]);

  useEffect(() => {
    if (
      entry?.isIntersecting &&
      pastLaunchesFiltered.length > pastLaunchResults.length
    ) {
      setPage(page + 1);
    }
  }, [entry, page, pastLaunchResults, pastLaunchesFiltered]);

  const loadMore = useMemo(() => {
    if (pastLaunchesFiltered.length > pastLaunchResults.length) {
      return <div className="caps xsmall cw py1 my1 pointer under">Loading...</div>;
    } else {
      return <span />;
    }
  }, [pastLaunchResults, pastLaunchesFiltered]);

  return (
    <div className="template__launches">
      <MetaModule title="Launches" path="/launches" />
      <div ref={content}>
        {nextLaunch !== undefined && (
          <div className="bcy rel launch__next-big">
            <Link
              to={`/launches/${nextLaunch.slug.current}`}
              className="abs z1 x y top left"
            />
            <div className="container--xl ma px15 pb15 pt1">
              <div className="pb1">
                <UpcomingLaunchCard launch={nextLaunch} />
              </div>
            </div>
          </div>
        )}
        <div className="bcg">
          <div className="al">
            <div className="container--xl ma p15 launch__index">
              <h6 className="cb caps">Upcoming Launches</h6>
              <div className="article__filters launch__filters pb15 f jcc cb aic caps" />
              <div className="f jcb ais fw">
                {upcomingLaunches.length ? (
                  upcomingLaunches
                    .slice(1, 4)
                    .map((launch) => (
                      <UpcomingLaunchCard key={launch._id} launch={launch} />
                    ))
                ) : (
                  <div className="article__filters-empty ac h3 p15 f cb jcc aic">
                    {`We're sorry, there's currently nothing launching `}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bcg">
          <div className="container--xl ma p15">
            <div>
              {upcomingLaunches.length > 3 && (
                <div className="f jcb ais cb fw">
                  {upcomingLaunches
                    .slice(4, upcomingLaunches.length)
                    .map((launch) => (
                      <LaunchCard key={launch._id} launch={launch} />
                    ))}
                  <div className="launch__card rel" />
                </div>
              )}
            </div>
          </div>
          <div className="bcb cw rel launch__archive">
            <div className="container--xl ma p15">
              <h6 className="cw caps">Past Launches</h6>
              <div className="article__filters launch__filters archive pb15 f fw jcc cw aic caps">
                {tags.map((tag) => (
                  <div
                    key={tag._id}
                    style={{ color: tag.color.hex, minWidth: '150px' }}
                    className={classNames('m1 small inline-block rel cw', {
                      active: selectedTag?._id === tag._id
                    })}
                    onClick={() => setSelectedTag(tag)}
                  >
                    <span>{tag.title}</span>
                  </div>
                ))}
              </div>
              {selectedTag && (
                <div className="my1 f cw jcs aic">
                  <p className="mr1">
                    {`You're viewing all `}
                    <em>
                      <span className="capitalize">
                        {' '}
                        {selectedTag.title.replace(/-/g, ' ')}
                      </span>
                    </em>{' '}
                    content
                  </p>
                  <span
                    className="article__filters-clear small caps"
                    onClick={() => {
                      setSelectedTag(undefined);
                      setPage(1);
                    }}
                  >
                    Clear Filter
                  </span>
                </div>
              )}
              <div>
                {pastLaunchResults.length > 0 ? (
                  <div className="f jcb ais fw">
                    {pastLaunchResults.map((launch) => (
                      <LaunchArchiveCard key={launch._id} launch={launch} />
                    ))}
                    <div className="launch__card rel" />
                  </div>
                ) : (
                  <div className="article__filters-empty ac h3 p15 f cw jcc aic">
                    {selectedTag
                      ? `We're sorry, there's currently nothing posted in ${selectedTag?.title.replace(
                          /-/g,
                          ' '
                        )}`
                      : `We're sorry, there are no launches in the archive right now.`}
                  </div>
                )}
              </div>
              <div className="x jcc f aic cw py1" ref={loadMoreRef}>
                {loadMore}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomStation;
