import React, { FC, useMemo } from 'react';
import { Link } from 'gatsby';
import { deriveLaunchDescription, Launch, useCountdownToLaunch } from 'superclient';
import ImageSanity from '../core/image-sanity';

const UpcomingLaunchCard: FC<{ launch: Launch }> = ({ launch }) => {
  const {
    title,
    tags,
    slug,
    image,
    launchInfo: { overrideLaunchDate, overrideLaunchTime } = {
      launchDescription: 'None'
    }
  } = launch;

  const overrideDate =
    overrideLaunchDate !== undefined && overrideLaunchDate.length > 0
      ? overrideLaunchDate
      : '';

  const countdownComponents = useCountdownToLaunch(launch);
  const description = useMemo(
    () => deriveLaunchDescription(launch, countdownComponents),
    [launch, countdownComponents]
  );

  return (
    <div className="f fw jcb ais launch__upcoming x rel">
      <Link to={`/launches/${slug.current}`} className="abs top z2 left x y" />
      <div className="launch__upcoming-text rel z1">
        <h3>{description}</h3>
        <div className="f jcs aic">
          {overrideDate ? (
            <div className="block">
              <span className="caps xsmall akkura bcb cw p05">{overrideDate}</span>
            </div>
          ) : (
            <>
              {countdownComponents?.days > 0 ? (
                <div className="block">
                  <span className="caps xsmall akkura bcb cw p05">
                    {countdownComponents?.days}{' '}
                    {countdownComponents?.days === 1 ? 'day' : 'days'}
                  </span>
                </div>
              ) : (
                <div className="block">
                  {countdownComponents?.hours > 0 && (
                    <span className="caps xsmall akkura bcb cw p05">
                      {countdownComponents?.hours}{' '}
                      {countdownComponents?.hours === 1 ? 'hour' : 'hours'}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
          <div className="article__filters launch__filters ml1 black f jcc cb aic caps">
            {tags &&
              tags.map((tag) => (
                <div
                  key={tag._id}
                  className="mr1 active inline-block rel small"
                  style={{ color: tag.color.hex }}
                >
                  <span>{tag.title}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="launch__track rel z1">
        <h6 className="caps f aic jce">
          Track Launch <span className="arrow__right arrow ml05 block cb rel" />
        </h6>
        <ImageSanity image={image} alt={title} width={500} />
      </div>
    </div>
  );
};

export default React.memo(UpcomingLaunchCard);
