import React, { FC } from 'react';
import { Link } from 'gatsby';
import { DateTime } from 'luxon';
import { Launch } from 'superclient';
import ImageBackgroundSanity from '../core/image-background-sanity';

const LaunchArchiveCard: FC<{ launch: Launch }> = ({ launch }) => {
  const { slug } = launch;
  const { company, launchDate, launchType } = launch.launchInfo;

  return (
    <div className="launch__card cw x rel mb15">
      <Link to={`/launches/${slug.current}`} className="abs z1 x y top left" />
      <div className="launch__card_image rel">
        <ImageBackgroundSanity
          className="abs x y top left"
          image={launch.image}
          alt={launchType}
          width={500}
        />
      </div>
      <div className="f jcb ais mt05">
        <div>
          <span>
            {company}
            <br />
            {launchType}
          </span>
        </div>
        <div>
          <span className="caps xsmall akkura ml1 launch__card-timing bcw cb p05">
            {DateTime.fromISO(launchDate.utc).toFormat('MMMM d, y')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LaunchArchiveCard;
